import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import AboutDirector from "../components/AboutDirector";
import Topbar from "../components/Topbar";
const AboutDirectorPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Director Desk">
            <Topbar/>
            <NavOne />
            <PageHeader title="Director Desk" />
            <AboutDirector />
            <Footer />
        </Layout>
    );
};

export default AboutDirectorPage;
