import React from "react";
import director from "../assets/images/director.jpeg";

const AboutDirector = () => {
  return (
    <section className="team-details">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="team-details__content">
              <h2 className="team-details__title title">Director's Desk</h2>
              <p className="team-details__text">
                It is my firm belief that the “School System “must enable the child to live together in mutual
                empowerment and create common good for the humanity. With the emergence of globalization,
                dilution of boundaries is taking place and today’s world calls for partnership, alliances,
                technological corporation, interdependence and collaboration. Therefore we recognize the
                challenges of closer involvement in educating the child from macro to the micro linkages, Such an
                approach involves aspects of quality thinking and develops a Scientific Temper and Positive
                Attitude .We prepare them on both parameters of life i.e. to work independently as an individual,
                as well as to work as part of a team.<br />
                The slogan of the school is “सा विद्या या विमुक्तये।” (Education is that which liberates) keeping this as top priority SAS Surat desires for every child to grow up to be a self-reliant, responsive, efficient, committed and ready to make the world a better place.
              </p>

            </div>
          </div>
          <div className="col-lg-6">
            <div className="team-one__single">
              <div className="team-one__image">
                <img src={director} alt="" />
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <a href="#">Regards</a>
                </h2>
                <p className="team-one__designation">Bhupendra Malviya</p>
                <p className="team-one__designation"><strong>M.Sc - (Physics), M.Ed.</strong></p>
                <p className="team-one__designation"><strong>Experience - 20+ years</strong></p>
              </div>
              {/* <div className="team-one__social">
                <a href="#none">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-pinterest-p"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-instagram"></i>
                </a>
              </div> */}
            </div>
          </div>
          {/* <div className="row"> */}
          <div className="col-lg-10">
            <p className="team-details__text">
              We educate students how to understand, contribute to, and succeed in a rapidly changing society, improving and justifying the world in the process. We'll make sure that our students learn both the skills that come with a good education and the skills that are required for success and leadership in the emerging creative world. <br />
              We will also lead in the development of both practical and theoretical knowledge. We facilitate a healthy competitive culture that is student-centric at its core. We develop exceptional young individuals through the positive and transformative life experiences that healthy competition can provide.<br />
              We try to create safe, respectful and loving environments within schools that attracts students to engage in the many opportunities that exist within healthy competition—whether it’s Academics, Fine arts, Sports, karate, Skating, Dance, Music etc. The Infrastructure of the school includes air-conditioned classroom with smart boards having latest technology that helps the students to understand better the relevant subject and concepts. The school provides different grounds for Football Basketball, Cricket and Volleyball. Not only this we has various sports facilities like skating Rink , tennis court , badminton court and table tennis room in the campus. Swimming lowers the level of anxiety and improves mental health keeping this in mind we always encourage our students to take part in splash Activity.<br />
              <strong>“Education is not the learning of facts, but the training of the mind to think” -Albert Einstein</strong><br />
              Our school’s vision is to we provide student-centered educational programs that challenge all students to perform at their highest potential. The school work for its children and we make sure that proper care of each and every child is taken to transform into a balance personality. We at SAS work tirelessly with dedication and determination always trying to extend the horizons of our knowledge on educating and developing children because it is easy to be an average but tough to the “The Best”. Therefore we believe that every child deserves our utmost care, love, and opportunity to express themselves so that they may see and appreciate the colors of the spectrum that life holds and becomes a good and responsible citizen of our nation.
            </p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};

export default AboutDirector;
